
























import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StudyUnitInformation from '@/components/organisms/StudyUnitInformation.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import { StudyHistory } from '@/models/api/history/studyUnits'
import StudyUnitWebApi from '@/mixins/history/StudyUnitWebApi'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'
import StudyUnitWebApiV3 from '@/mixins/historyv3/StudyUnitWebApi'
import { StudyHistory as StudyHistoryV3 } from '@/models/api/v3/history/studyUnits'
import StudyUnitInformationV3 from '@/components/organisms/v3/StudyUnitInformation.vue'
import LearningType, { LearningTypePage } from '@/mixins/historyv3/LearningType'

@Component({
  components: {
    ButtonBase,
    StudyUnitInformation,
    TitleBase,
    TitleTextBase,
    StudentHistorySidebar,
    TeacherHistoryBottomPanel,
    StudyUnitWebApiV3,
    StudyUnitInformationV3,
  },
})
export default class StudyUnit extends Mixins(StudyUnitWebApi, LearningType) {
  // サイドメニュー出し分け用として、normal/test/seminar/entrance/assist-material のタイプを受け取っている
  @Prop()
  learningType!: string

  // 定期テストモードではterm_exam_id、入試モードではentrance_exam_information_id、それ以外はcurriculum_s_unit_id
  @Prop()
  id!: string

  private studies: { title: string; studyUnitDatas: StudyHistory[] | StudyHistoryV3[] }[] = [
    {
      title: '',
      studyUnitDatas: [],
    },
  ]

  @Ref() studyUnitWebApiV3!: StudyUnitWebApiV3

  // learningType => classModeCodeへ変換
  private get classModeCode() {
    switch (this.learningType) {
      case 'normal':
        return this.isV3 ? 'AI' : 'TJ'
      case 'test':
        return 'TK'
      case 'seminar':
        return 'KS'
      case 'entrance':
        return 'NY'
      case 'assist-material':
        return 'HJ'
      case 'normal-v1':
        return 'TJ'
      case 'custom':
        return this.$route.query.modeCode as string
      default:
        return ''
    }
  }

  private get componentStudyUnit() {
    const componentV3 = ['normal', 'custom'].includes(this.learningType) && this.isV3
    return componentV3 ? 'StudyUnitInformationV3' : 'StudyUnitInformation'
  }

  private get studentUserId() {
    return Vue.prototype.$cookies.get('dataGdls')?.student?.userId
  }

  private async mounted() {
    Vue.prototype.$loading.start()
    this.id === 'all' ? await this.loadAllHistories() : await this.loadStudyHistories()
    Vue.prototype.$loading.complete()
  }

  private async loadAllHistories() {
    let curriculumSUnitIds: number[] = []
    try {
      curriculumSUnitIds = JSON.parse(this.$route.query.curriculumSUnitIds as string)
    } catch {
      curriculumSUnitIds = []
    }
    const params = {
      userId: this.studentUserId,
      curriculumSUnitIds,
    }
    try {
      const res = await this.studyUnitWebApiV3.loadStudyHistoriesAllApi(params)
      this.studies = res.map((item) => ({ title: item.title, studyUnitDatas: item.studyHistories }))
    } catch {
      this.$router.back()
    }
  }

  private async loadStudyHistories() {
    const params = {
      userId: this.studentUserId,
      classModeCode: this.classModeCode,
    }
    const modeCode = this.classModeCode === 'TJ' ? 'TJ,KS,AI' : this.classModeCode
    const paramsV3 = {
      userId: this.studentUserId,
      classModeCode: modeCode,
      curriculumSUnitId: +this.id,
      classCategoryCode: 'RK,EN',
    }

    // 同じIDを用いるのですが、意味合いが異なるためキー名は分離しています
    switch (this.classModeCode) {
      case 'TK':
        params['termExamId'] = this.id
        break
      case 'NY':
        params['resultDrillEntranceExamInformationId'] = this.id
        break
      default:
        params['curriculumSUnitId'] = this.id
        break
    }

    const isCallApiV3 =
      this.isV3 && [LearningTypePage.Custom, LearningTypePage.Normal].includes(this.currentLearningType)
    const res = isCallApiV3
      ? await this.studyUnitWebApiV3.loadStudyHistoriesApi(paramsV3)
      : await this.loadStudyHistoriesApi(params)
    this.studies = [
      {
        title: res.title,
        studyUnitDatas: res.studyHistories,
      },
    ]
  }

  private async movePrev() {
    const name = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''

    const targetQueryKeys = ['selectedSubject', 'grade', 'subject', 'unit', 'targetRadio', 'targetSelect', 'modeCode']
    const targetQuery = {}
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery[key] = value
    }

    if (Object.keys(targetQuery).length > 0) {
      this.$router.push({ name: name, query: targetQuery })
    } else {
      this.$router.push({ name: name })
    }
  }
}
