import { Component, Vue } from 'vue-property-decorator'
import { GetStudyUnitsFromUserId } from '@/models/api/v3/history/studyUnits'

@Component
export default class StudyUnitWebApi extends Vue {
  /**
   * 小項目毎の学習履歴一覧を取得する
   * @param { userId: number; classModeCode: string; curriculumSUnitId: number; classCategoryCode: string }
   */
  public async loadStudyHistoriesApi(params: {
    userId: number
    classModeCode: string
    curriculumSUnitId: number
    classCategoryCode: string
  }) {
    const { data }: { data: GetStudyUnitsFromUserId } = await Vue.prototype.$http.httpWithToken.get(
      `/v3/history/study_units`,
      {
        params: params,
      }
    )

    // 項目毎の累計理解度セット
    return data
  }

  public async loadStudyHistoriesAllApi(params: { userId: number; curriculumSUnitIds: number[] }) {
    const { data }: { data: GetStudyUnitsFromUserId[] } = await Vue.prototype.$http.httpWithToken.get(
      `/v3/history/learning_datas`,
      {
        params: {
          user_id: params.userId,
          curriculum_s_unit_id: params.curriculumSUnitIds,
        },
      }
    )

    // 項目毎の累計理解度セット
    return data
  }
}
