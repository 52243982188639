






































import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import RatioBarText from '@/components/atoms/RatioBarText.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import { StudyHistory } from '@/models/api/v3/history/studyUnits'

@Component({
  components: {
    ColoredBox,
    RatioBarText,
  },
})
export default class StudyUnitInformation extends Mixins(StyleSubjectBackground) {
  @Prop({ default: 'normal' })
  learningType!: string

  @Prop()
  studyHistory!: StudyHistory

  @Prop({ default: false })
  isTeacher!: boolean

  private get isEntranceMode() {
    return this.learningType == 'entrance'
  }

  private get barStyleSet(): any {
    return function () {
      let fillColor = null
      let lineColor = null
      const code = this.studyHistory.subjectCode

      if (this.isTeacher) {
        fillColor = 'var(--color-blue)'
        lineColor = 'var(--color-blue-3)'
      } else {
        const subjectStyle = this.getSubjectStyle(code)
        fillColor = subjectStyle['--subject-bg-color']
        lineColor = subjectStyle['--subject-linear-gradiant']
      }
      return {
        '--bar-fill-color': fillColor,
        '--bar-line-color': lineColor,
      }
    }
  }

  // 理解度グラフテキスト
  private get rateText() {
    return `${this.studyHistory.minPredictedScore}-${this.studyHistory.maxPredictedScore}%`
  }

  private drillResultUrl(resultDrillId: number, isV2: boolean): string {
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    const beforePage = Array.isArray(this.$route.query.prevPage)
      ? this.$route.query.prevPage[0] || ''
      : this.$route.query.prevPage || ''
    const prev = `prevPage=${this.$route.name}&prevId=${this.$route.params.id}&beforePage=${beforePage}${
      isV3 && !this.isTeacher ? '' : `&prevType=${this.$route.params.learningType}`
    }`

    const targetQueryKeys = ['selectedSubject', 'grade', 'subject', 'unit', 'targetRadio', 'targetSelect', 'modeCode']
    let targetQuery = ''
    for (const [key, value] of Object.entries(this.$route.query)) {
      if (targetQueryKeys.includes(key)) targetQuery += `&${key}=${value}`
    }
    const linkDrillResultV1 = `history/drillResult/${resultDrillId}?${prev}`
    const linkDrillResultV3 = `v3/history/drillResult/?resultDrillId=${resultDrillId}&${prev}`
    const base = isV3 ? (isV2 ? linkDrillResultV3 : linkDrillResultV1) : linkDrillResultV1

    return this.isTeacher ? `/teacher/${base}${targetQuery}` : `/student/${base}${targetQuery}`
  }
}
